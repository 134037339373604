import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import { ConnectSpotifyCTACard } from '@/components/cards/ConnectSpotifyCTACard';
import Card from '@/components/utility/microcomponents/Card';
import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useArtist from '@/hooks/artist/useArtist';
import { useLocalStorage } from '@/services/LocalStorage';

const ExplainerScreen = ({ type, nextStep }: { type?: string; nextStep: () => void }) => {
  const { isSubscribed } = useSubscription();
  const { artist, artistIsLoading, artistError } = useArtist();

  const [noArtist, setNoArtist] = useState<boolean>(false);
  const [showNoSpotify, setShowNoSpotify] = useState<boolean>(false);

  const { setItem } = useLocalStorage();

  const isBio = type === 'artist-bio';
  const isPressRelease = type === 'press-release';

  useEffect(() => {
    if (!artistIsLoading && !artist) return setNoArtist(true);
  }, [artist, artistError, artistIsLoading]);

  const canStep = () => {
    if (noArtist) {
      setShowNoSpotify(true);
    } else {
      nextStep();
    }
  };

  const dismissExplainerScreen = () => {
    if (type === 'artist-bio') {
      setItem('dismissedExplainerScreenBio', true);
    } else if (type === 'press-release') {
      setItem('dismissedExplainerScreenPressRelease', true);
    } else if (type === 'basic-info') {
      setItem('dismissedExplainerScreenBasicInfo', true);
    }
    canStep();
  };

  return (
    <>
      {!showNoSpotify && (
        <div className="text-center max-w800 ml-auto mr-auto mb150 p10">
          <h2>
            {isBio ? "What's an artist bio?" : isPressRelease ? "What's a press release?" : 'How we use your info'}
          </h2>

          <img
            src={
              isBio
                ? Images.placeholders.artistBio
                : isPressRelease
                  ? Images.placeholders.pressRelease
                  : Images.placeholders.basicInfo
            }
            alt="bio-image"
            width={isBio ? '100%' : isPressRelease ? '80%' : '60%'}
            className={`mt40 ml-auto mr-auto ${!isPressRelease ? '' : 'card p10 max-w600'}`}
          />
          {(isSubscribed || isBio || isPressRelease) && (
            <p className="mt20">
              {isBio
                ? 'An artist bio should showcase your identity and journey. It’s an introduction for your fans that highlights your background, influences, and achievements. A good bio helps you connect with your audience, industry professionals, and media by providing insight into who you are as an artist.'
                : isPressRelease
                  ? 'A press release is an official announcement to share news about upcoming projects, events, or achievements. It is used to inform media outlets and fans about new music releases, tours, or collaborations, creating buzz and excitement. '
                  : 'We’ll ask you a few short questions about your life and your music. We save this information so you only have to answer these questions once.\n\nTo create generations, you will need a Spotify Artist profile.'}
            </p>
          )}
          {!isBio && !isPressRelease && !isSubscribed && (
            <div className="to-do-card-border mt40">
              <Card className="to-do-card p10 text-left">
                <div className="pos-abs t10 r10">
                  <img src="/images/logos/subscription-pro-lock-logo-light.svg" alt="lock" />
                </div>
                <h5>Creating generations</h5>
                <p className="text-faded mt20">
                  It is important to note that Lyra is a Pro feature. You can still fill our questions about your life
                  and your music. You only have to answer these questions once. You will also need a Spotify Artist
                  profile to create generations.
                </p>
              </Card>
            </div>
          )}
          <div className="mt40">
            <Button className="btn-white min-w200" onClick={canStep}>
              Get Started
            </Button>
          </div>
          <div>
            <Button className="min-w200" onClick={dismissExplainerScreen}>
              Don't show me this again
            </Button>
          </div>
        </div>
      )}
      {showNoSpotify && <ConnectSpotifyCTACard goBack={() => setShowNoSpotify(false)} isBack />}
    </>
  );
};

export default ExplainerScreen;
