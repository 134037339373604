import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Chip, Icon, MenuItem, Select } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';

import AddCollaboratorModal from '@/components/utility/modals/AddCollaboratorModal';
import AddGenreModal from '@/components/utility/modals/AddGenreModal';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

type ReleaseSelectionProps = {
  name: string;
  value: string;
  date: string;
};

const ReleaseSelection = ({
  isPressRelease,
  canPage,
}: {
  isPressRelease?: boolean;
  canPage?: (output: boolean) => void;
}) => {
  const { register, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const formValues = watch();

  const [releaseCycleOptions, setReleaseCycleOptions] = useState<ReleaseSelectionProps[]>([]);
  const [selectedReleaseCycle, setSelectedReleaseCycle] = useState<ReleaseSelectionProps>(releaseCycleOptions[0]);

  const [isGenresModalOpen, setIsGenresModalOpen] = useState<boolean>(false);
  const [isCollaboratorsModalOpen, setIsCollaboratorsModalOpen] = useState<boolean>(false);

  const { releaseCycles } = useReleaseCycles({
    isCompleted: false,
  });

  useEffect(() => {
    if (releaseCycles) {
      const options: ReleaseSelectionProps[] = [];
      if (!isPressRelease) {
        options.push({ name: "Don't include release info", value: 'none', date: '' });
      }
      releaseCycles.map((releaseCycle) => {
        return options.push({ name: releaseCycle.name, value: releaseCycle.id, date: releaseCycle.releaseDate });
      });
      options.push({ name: '+ Add new release', value: 'new', date: new Date().toISOString() });
      setReleaseCycleOptions(options);
      const index = options.findIndex((option) => option.value === formValues.releaseType);
      setSelectedReleaseCycle(options[index] ?? options[0]);
    }
  }, [formValues.releaseType, isPressRelease, releaseCycles]);

  useEffect(() => {
    if (selectedReleaseCycle) {
      setValue('releaseType', selectedReleaseCycle.value);
    }
  }, [selectedReleaseCycle, setValue]);

  useEffect(() => {
    if (!selectedReleaseCycle?.value) return;
    if (selectedReleaseCycle?.value === 'none') {
      setValue('releaseDate', '');
      setValue('releaseTitle', '');
      setValue('releaseGenres', []);
      setValue('releaseCollaborators', []);
      setValue('releaseDescription', '');
    } else if (selectedReleaseCycle?.value !== 'new') {
      const releaseCycle = releaseCycles?.find((releaseCycle) => releaseCycle.id === selectedReleaseCycle?.value);
      setValue('releaseDate', selectedReleaseCycle?.date);
      setValue('releaseTitle', selectedReleaseCycle?.name);
      setValue('releaseGenres', releaseCycle?.genres);
      setValue('releaseCollaborators', releaseCycle?.collaborators || []);
      setValue('releaseDescription', releaseCycle?.description);
    } else if (selectedReleaseCycle?.value === 'new') {
      setValue('releaseDate', formValues.releaseDate || selectedReleaseCycle?.date);
    }
    setValue('releaseCycleId', selectedReleaseCycle?.value);
  }, [formValues.releaseDate, releaseCycles, selectedReleaseCycle, setValue]);

  const handleAddGenre = useCallback(
    (genre: string) => {
      if (formValues.releaseGenres?.includes(genre)) return;
      setValue('releaseGenres', [...(formValues.releaseGenres ?? []), genre]);
      setIsGenresModalOpen(false);
    },
    [formValues.releaseGenres, setValue]
  );

  const handleRemoveGenre = useCallback(
    (genre: string) => {
      setValue(
        'releaseGenres',
        (formValues.releaseGenres ?? []).filter((value: string) => value !== genre)
      );
      setIsGenresModalOpen(false);
    },
    [formValues.releaseGenres, setValue]
  );

  const handleAddCollaborator = useCallback(
    (collaborator: string) => {
      if (formValues.releaseCollaborators?.includes(collaborator)) return;
      setValue('releaseCollaborators', [...(formValues.releaseCollaborators ?? []), collaborator]);
      setIsCollaboratorsModalOpen(false);
    },
    [formValues.releaseCollaborators, setValue]
  );

  const handleRemoveCollaborator = useCallback(
    (collaborator: string) => {
      setValue(
        'releaseCollaborators',
        (formValues.releaseCollaborators ?? []).filter((value: string) => value !== collaborator)
      );
      setIsCollaboratorsModalOpen(false);
    },
    [formValues.releaseCollaborators, setValue]
  );

  const isFormValid = useCallback(() => {
    if (selectedReleaseCycle?.value !== 'none' && !isPressRelease) {
      return !!formValues.releaseDate && !!formValues.releaseTitle && !!formValues.releaseGenres?.length;
    } else if (selectedReleaseCycle?.value === 'none' && !isPressRelease) {
      return true;
    } else if (isPressRelease) {
      return (
        !!formValues.releaseDate &&
        !!formValues.releaseTitle &&
        !!formValues.releaseDescription &&
        !!formValues.releaseGenres?.length
      );
    } else {
      return false;
    }
  }, [
    selectedReleaseCycle?.value,
    isPressRelease,
    formValues.releaseDate,
    formValues.releaseTitle,
    formValues.releaseGenres?.length,
    formValues.releaseDescription,
  ]);

  useEffect(() => {
    canPage && canPage(isFormValid());
    return () => {
      return canPage && canPage(true);
    };
  }, [canPage, isFormValid]);

  return (
    <div className="text-center max-w600 ml-auto mr-auto mt40 mb300">
      <AddGenreModal
        title={'COMMON.ADD-GENRES'}
        isOpen={isGenresModalOpen}
        onClose={() => setIsGenresModalOpen(false)}
        outputGenre={(genre: string) => {
          handleAddGenre(genre);
          setIsGenresModalOpen(false);
        }}
      />
      <AddCollaboratorModal
        isOpen={isCollaboratorsModalOpen}
        onClose={() => {
          setIsCollaboratorsModalOpen(false);
        }}
        output={(collaborator: string) => {
          handleAddCollaborator(collaborator);
          setIsCollaboratorsModalOpen(false);
        }}
      />
      <h2>{isPressRelease ? 'Create a press release' : 'Include a release in your bio?'}</h2>
      <p className="text-faded mt10">
        {isPressRelease
          ? 'Your press release will be based around an upcoming music release'
          : 'We recommend including information about your latest release in your bio to keep your fans up-to-date'}
      </p>
      {((!isPressRelease && releaseCycleOptions?.length > 0) ||
        (isPressRelease && releaseCycles && releaseCycles?.length > 0)) && (
        <Select
          className="w100p mt20"
          placeholder="Select a release"
          defaultValue={formValues.releaseType}
          value={selectedReleaseCycle?.value}
          onChange={(e) => {
            const selected = releaseCycleOptions.find((option) => option.value === e.target.value);
            selected && setSelectedReleaseCycle(selected);
            setValue('releaseType', e.target.value);
          }}
        >
          {releaseCycleOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {formValues.releaseType && formValues.releaseType !== 'none' && (
        <div className="mt20">
          <label className="flex-grow">
            <p className="mt20 fw-bold">Title</p>
            <input
              {...register('releaseTitle')}
              placeholder="What's the title of the release?"
              disabled={selectedReleaseCycle?.value !== 'new'}
              defaultValue={formValues.releaseTitle}
              value={formValues.releaseTitle}
            />
          </label>
          <label className="flex-grow">
            <p className="mt20 fw-bold">Release Date</p>
            {selectedReleaseCycle?.value === 'new' && (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
                <DesktopDatePicker
                  format="do MMM yyyy"
                  disabled={selectedReleaseCycle?.value !== 'new'}
                  value={
                    formValues.releaseDate
                      ? parseISO(new Date(formValues.releaseDate).toISOString())
                      : parseISO(new Date().toISOString())
                  }
                  onChange={(value) => {
                    setValue('releaseDate', value);
                  }}
                />
              </LocalizationProvider>
            )}
            {selectedReleaseCycle?.value !== 'new' && (
              <input
                {...register('releaseDate')}
                disabled={selectedReleaseCycle?.value !== 'new'}
                placeholder="When was the release?"
                defaultValue={selectedReleaseCycle?.date}
              />
            )}
          </label>

          <label className="flex-grow">
            <div className="d-flex">
              <div>
                <p className="mt20 fw-bold mb0">Genres (required)</p>
                <p className="small text-faded pt0">Which genres best describe this release?</p>
              </div>
              {!breakpointHit && (!formValues.releaseGenres || formValues.releaseGenres?.length === 0) && (
                <Button
                  className="btn-black-2 ml-auto mr0 mt-auto mb-auto"
                  onClick={() => {
                    setIsGenresModalOpen(true);
                  }}
                >
                  <span className="d-flex">
                    <Icon>add</Icon>
                    <span className="pl8 pt1">{t('COMMON.ADD-GENRE')}</span>
                  </span>
                </Button>
              )}
            </div>
            <div className="d-flex jc-start gap8 mt8 flex-wrap">
              {formValues?.releaseGenres?.map((item: string) => (
                <Chip
                  key={item}
                  label={<p className="capitalize mt4">{item}</p>}
                  className="text-left"
                  data-testid={`social-chip-${item}`}
                  deleteIcon={<Icon data-testid={`genre-${item}-delete`}>cancel</Icon>}
                  onDelete={(e) => {
                    e.preventDefault();

                    handleRemoveGenre(item);
                  }}
                />
              ))}
              {formValues.releaseGenres?.length > 0 && (
                <Button
                  className="btn-black-2 m0 p4"
                  onClick={() => {
                    setIsGenresModalOpen(true);
                  }}
                >
                  <span className="d-flex">
                    <Icon>add</Icon>
                    <span className="pl8 pt1">{t('COMMON.ADD-GENRE')}</span>
                  </span>
                </Button>
              )}
            </div>
            {breakpointHit && (!formValues.releaseGenres || formValues.releaseGenres?.length === 0) && (
              <div className="d-flex">
                <Button
                  className="btn-white ml-auto mb0 w100p mr0"
                  onClick={() => {
                    setIsGenresModalOpen(true);
                  }}
                >
                  <span className="d-flex">
                    <Icon>add</Icon>
                    <span className="pl8 pt1">{t('COMMON.ADD-YOUR-GENRES')}</span>
                  </span>
                </Button>
              </div>
            )}
          </label>
          <label className="flex-grow">
            <div className="d-flex">
              <div>
                <p className="mt20 fw-bold mb0">Collaborators</p>
                <p className="small text-faded pt0">Did you work with anyone on this track?</p>
              </div>
              {!breakpointHit &&
                (!formValues.releaseCollaborators || formValues.releaseCollaborators?.length === 0) && (
                  <Button
                    className="btn-black-2 ml-auto mr0 mt-auto mb-auto"
                    onClick={() => {
                      setIsCollaboratorsModalOpen(true);
                    }}
                  >
                    <span className="d-flex">
                      <Icon>add</Icon>
                      <span className="pl8 pt1">{t('COMMON.ADD-COLLABORATOR')}</span>
                    </span>
                  </Button>
                )}
            </div>
            <div className="d-flex jc-start gap8 mt8 flex-wrap">
              {formValues.releaseCollaborators?.map((item: string) => (
                <Chip
                  key={item}
                  label={<p className="capitalize mt4">{item}</p>}
                  className="text-left p10"
                  data-testid={`social-chip-${item}`}
                  deleteIcon={<Icon data-testid={`collaborator-${item}-delete`}>cancel</Icon>}
                  onDelete={(e) => {
                    e.preventDefault();
                    handleRemoveCollaborator(item);
                  }}
                />
              ))}
              {formValues.releaseCollaborators?.length > 0 && (
                <Button
                  className="btn-black-2 m0 p4"
                  onClick={() => {
                    setIsCollaboratorsModalOpen(true);
                  }}
                >
                  <span className="d-flex">
                    <Icon>add</Icon>
                    <span className="pl8 pt1">{t('COMMON.ADD-COLLABORATOR')}</span>
                  </span>
                </Button>
              )}
            </div>
            {breakpointHit && (!formValues.releaseCollaborators || formValues.releaseCollaborators?.length === 0) && (
              <div className="d-flex">
                <Button
                  className="btn-white ml-auto mb0 w100p mr0"
                  onClick={() => {
                    setIsCollaboratorsModalOpen(true);
                  }}
                >
                  <span className="d-flex">
                    <Icon>add</Icon>
                    <span className="pl8 pt1">{t('COMMON.ADD-COLLABORATOR')}</span>
                  </span>
                </Button>
              </div>
            )}
          </label>
          <label className="flex-grow">
            <p className="mt20 fw-bold">Description</p>
            <textarea
              {...register('releaseDescription')}
              placeholder="Describe your release in a few words"
              defaultValue={selectedReleaseCycle?.date}
            />
          </label>
        </div>
      )}
    </div>
  );
};

export default ReleaseSelection;
