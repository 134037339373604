import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Icon } from '@mui/material';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import Card from '@/components/utility/microcomponents/Card';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import AddCareerEventModal from '@/components/utility/modals/AddCareerEventModal';
import { CareerHistoryObject } from '@/models/MarketingAssistant';

const YourCareer = () => {
  const [addEventModalOpen, setAddEventModalOpen] = useState<boolean>(false);
  const { setValue, watch } = useFormContext();
  const formState = watch();

  return (
    <>
      <AddCareerEventModal
        open={addEventModalOpen}
        output={(event) => {
          if (event) {
            setValue('careerHistory', [...(formState.careerHistory || []), event as CareerHistoryObject]);
          }
          setAddEventModalOpen(false);
        }}
      />
      <div className="text-center max-w500 ml-auto mr-auto mt40 mb300">
        <h2>Your Career</h2>
        <p className="small text-faded mt10 mb20">Tell us about notable dates of your life and career</p>
        {formState?.careerHistory &&
          Array.isArray(formState?.careerHistory) &&
          formState?.careerHistory
            ?.sort(
              (a: CareerHistoryObject, b: CareerHistoryObject) =>
                new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            .reverse()
            .map((item, index) => (
              <Card innerInner key={index} className="d-flex gap10 mt10 p10">
                <h5 className="mt-auto mb-auto min-w100">{item.date}</h5>
                <p className="mt-auto mb-auto text-left word-break">{item.description}</p>
                <ButtonComponent
                  isCustom={true}
                  className="mt-auto mb-auto ml-auto"
                  onClick={() => {
                    const newCareerHistory = formState.careerHistory?.filter((i: CareerHistoryObject) => i !== item);
                    if (!newCareerHistory) return;
                    setValue('careerHistory', newCareerHistory);
                  }}
                >
                  <CustomIcons className="material-symbols-outlined text-error" name="delete" />
                </ButtonComponent>
              </Card>
            ))}
        <div className="w100p d-flex">
          <Button
            className={`btn-white mt10 ml-auto mr0 ${formState?.careerHistory?.length ? '' : 'w100p mt40'}`}
            onClick={() => {
              setAddEventModalOpen(true);
            }}
          >
            <Icon>add</Icon>
            <span className="fw-bold">Add event</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default YourCareer;
