import React from 'react';
import { useFormContext } from 'react-hook-form';

const YourStory = () => {
  const { register } = useFormContext();

  return (
    <div className="text-center max-w500 ml-auto mr-auto mt40 mb300">
      <h2>Your Story</h2>

      <p className="mt40 text-left fw-bold">Origin story</p>
      <p className="small text-faded text-left">Tell us about your journey into music and what inspired you</p>
      <textarea
        className="mt10"
        placeholder="Tell us a bit about yourself"
        {...register('originStory')}
        maxLength={500}
      />

      <label className="flex-grow">
        <p className="mt20 fw-bold">Birth Location</p>
        <input placeholder="Where were you born?" {...register('birthLocation')} maxLength={30} />
      </label>
      <label className="flex-grow">
        <p className="mt20 fw-bold">Currently Based</p>
        <input placeholder="Where do you live?" {...register('currentLocation')} maxLength={30} />
      </label>
    </div>
  );
};

export default YourStory;
