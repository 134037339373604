import React from 'react';

import { SocialMediaInputs } from '../../../utility/form-inputs/SocialMediaInputs';

const YourSocials = () => {
  return (
    <div className="text-center max-w500 ml-auto mr-auto mt40 mb300">
      <h2>Your Socials</h2>
      <p className="small text-faded mb40">Fill in your social handles for all of the platforms you have</p>
      <SocialMediaInputs />
    </div>
  );
};

export default YourSocials;
