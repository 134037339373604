import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, CircularProgress, Icon } from '@mui/material';

import { ConnectSpotifyCTACard } from '@/components/cards/ConnectSpotifyCTACard';
import { MotionElement } from '@/components/utility/framer-motion-wrappers/MotionElement';
import { MotionWrapper } from '@/components/utility/framer-motion-wrappers/MotionWrapper';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useAccountArtistInfo from '@/hooks/marketing-assistant/useAccountArtistInfo';
import useMarketingAssistantContent from '@/hooks/marketing-assistant/useMarketingAssistantContent';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useUserTracking from '@/hooks/useUserTracking';
import { PromoteFlowQueryParams } from '@/models/Enums';
import { SurveyQueryParams } from '@/models/Enums';
import { MarketingAssistantUserInfoModel } from '@/models/MarketingAssistant';
import MarketingAssistantAPI from '@/network/MarketingAssistantAPI';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';
import { useLocalStorage } from '@/services/LocalStorage';

import NumberStepper from '../../utility/microcomponents/NumberStepper';
import DialogModal from '../../utility/modals/DialogModal';
import AIGenerationAndRefinement from './ai-generator-steps/AIGenerationAndRefinement';
import ExplainerScreen from './ai-generator-steps/ExplainerScreen';
import ReleaseSelection from './ai-generator-steps/ReleaseDetails';
import YourCareer from './ai-generator-steps/YourCareer';
import YourSocials from './ai-generator-steps/YourSocials';
import YourStory from './ai-generator-steps/YourStory';

const AIGeneratorModal = ({
  closeModalOutput,
  type,
  isEdit,
}: {
  closeModalOutput: () => void;
  type: string;
  isEdit?: boolean;
}) => {
  const { accountId } = useAccountContext();
  const userTracking = useUserTracking();
  const { dispatchSnackbar } = useSnackbarContext();
  const { artist } = useArtist();
  const { t } = useTranslation();
  const { getItem, setItem } = useLocalStorage();
  const [step, setStep] = useState<number>(0);
  const [direction, setDirection] = useState<'next' | 'back'>('next');
  const [canStep, setCanStep] = useState<boolean>(true);
  const [params, setSearchParams] = useSearchParams();

  const hasLoaded = useRef(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [contentGeneration, setContentGeneration] = useState<string>('');
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const { accountArtistInfo, refetchAccountArtistInfo } = useAccountArtistInfo();
  const { refetchMarketingAssistantContent } = useMarketingAssistantContent();

  const { releaseCycles, refetchReleaseCycles } = useReleaseCycles({
    isCompleted: false,
  });

  const flowType = useMemo(() => {
    if (isEdit) {
      return 'lyra-edit';
    }
    if (isFirstTime && type !== 'basic-info') {
      return 'lyra-full';
    }
    if (!isFirstTime && type !== 'basic-info') {
      return 'lyra-short';
    }
    if (type === 'basic-info') {
      return 'lyra-basic-info';
    }
    return '';
  }, [isEdit, isFirstTime, type]);

  const flow = useMemo(() => {
    if (isEdit) {
      return 'Edit';
    }
    if (type === 'basic-info') {
      return 'Basic Info';
    }
    if (type === 'artist-bio') {
      return 'Artist Bio';
    }
    if (type === 'press-release') {
      return 'Press Release';
    }
    return '';
  }, [isEdit, type]);

  const defaultValues = useMemo(() => {
    return {
      artistName: '',
      artistDescription: '',
      originStory: '',
      birthLocation: '',
      currentLocation: '',
      careerHistory: [],
      releaseCollaborators: [],
      releaseDate: '',
      releaseDescription: '',
      releaseGenres: [],
      releaseTitle: '',
      releaseType: '',
      socials: {
        facebook: '',
        instagram: '',
        twitter: '',
        youTube: '',
        snapChat: '',
        tikTok: '',
        discord: '',
      },
    };
  }, []);

  const formMethods = useForm<MarketingAssistantUserInfoModel>({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const {
    formState: { isValid },
  } = formMethods;

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;
    const firstTime = getItem(`ai-generator-first-time-${artist?.id}`);
    const bioDismissed = getItem(`dismissedExplainerScreenBio`);
    const pressReleaseDismissed = getItem(`dismissedExplainerScreenPressRelease`);
    const basicInfoDismissed = getItem(`dismissedExplainerScreenBasicInfo`);
    if (!firstTime) {
      setIsFirstTime(true);
    } else {
      setIsFirstTime(false);
    }
    if (type === 'artist-bio' && bioDismissed) {
      setStep(1);
    }
    if (type === 'press-release' && pressReleaseDismissed) {
      setStep(1);
    }
    if (type === 'basic-info' && basicInfoDismissed) {
      setStep(1);
    }
  }, [type, getItem, artist?.id]);

  const handleDialogOutput = useCallback(
    (output: boolean) => {
      if (output) {
        closeModalOutput();
        if (contentGeneration !== '') {
          params.set(SurveyQueryParams.LYRA_SURVEY, 'true');
        }
        params.delete(PromoteFlowQueryParams.ARTIST_BIO);
        params.delete(PromoteFlowQueryParams.PRESS_RELEASE);
        params.delete(PromoteFlowQueryParams.BASIC_INFO);
        setSearchParams(params);
        userTracking?.productExited?.({
          product: 'Lyra',
          productScreenName: PromoteFlowStepFormatter(flowType, step),
        });
      } else {
        setDialogOpen(false);
      }
    },
    [closeModalOutput, contentGeneration, flowType, params, setSearchParams, step, userTracking]
  );

  const updateUserInfo = useCallback(async () => {
    if (!accountId || !artist?.id) return;
    setIsSavingLoading(true);
    try {
      await MarketingAssistantAPI.updateMarketingAssistantUserInfo({
        accountId: accountId,
        artistId: artist?.id,
        data: formMethods.watch(),
      });
      if (type === 'basic-info') {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            type: 'success',
            message: 'Your artist info has been saved',
          },
        });
      }
      if (
        formMethods.watch('releaseType') !== 'none' &&
        formMethods.watch('releaseType') !== 'new' &&
        formMethods.watch('releaseType') !== ''
      ) {
        await ReleaseCyclesAPI.updateReleaseCycle({
          scheduleId: formMethods.watch('releaseCycleId'),
          details: {
            accountId: accountId,
            collaborators: formMethods.watch('releaseCollaborators') || [],
            genres: formMethods.watch('releaseGenres'),
            description: formMethods.watch('releaseDescription'),
            releaseDate: formMethods.watch('releaseDate'),
            name: formMethods.watch('releaseTitle'),
          },
        });
      }
    } finally {
      await refetchAccountArtistInfo();
      await refetchReleaseCycles();
      setItem(`ai-generator-first-time-${artist?.id}`, 'false');
      setIsSavingLoading(false);
      if (type === 'basic-info') {
        handleDialogOutput(true);
      }
    }
  }, [
    accountId,
    artist?.id,
    dispatchSnackbar,
    formMethods,
    handleDialogOutput,
    refetchAccountArtistInfo,
    refetchReleaseCycles,
    setItem,
    type,
  ]);

  useEffect(() => {
    if (accountArtistInfo) {
      formMethods.setValue('artistName', accountArtistInfo.artistName);
      formMethods.setValue('artistDescription', accountArtistInfo.artistDescription);
      formMethods.setValue('originStory', accountArtistInfo.originStory || '');
      formMethods.setValue('birthLocation', accountArtistInfo.birthLocation);
      formMethods.setValue('currentLocation', accountArtistInfo.currentLocation);

      formMethods.setValue('socials', {
        ...defaultValues.socials, // Ensures all fields exist
        ...Object.fromEntries(
          Object.entries(accountArtistInfo.socials || {}).map(([key, value]) => [key, value ?? ''])
        ),
      });

      formMethods.setValue('careerHistory', accountArtistInfo.careerHistory);
    }
  }, [accountArtistInfo, defaultValues, formMethods]);

  const saveGeneration = () => {
    if (!accountId || !releaseCycles) return;
    setIsSavingLoading(true);
    MarketingAssistantAPI.saveGeneration({
      data: {
        accountId: accountId,
        content: contentGeneration,
        type: type === 'artist-bio' ? 0 : 1,
      },
    })
      .then(() => {
        setIsSavingLoading(false);
        refetchMarketingAssistantContent();
        handleDialogOutput(true);
      })
      .catch(() => {
        setIsSavingLoading(false);
      });
  };

  const canPage = (output: boolean) => {
    setCanStep(output);
  };

  const trackProductViewed = useCallback(() => {
    if (!userTracking) return;

    return userTracking?.productViewed?.({
      product: 'Lyra',
      productScreenName: `${flow} - ${PromoteFlowStepFormatter(flowType, step)}`,
    });
  }, [userTracking, flow, flowType, step]);

  useEffect(() => {
    trackProductViewed();
  }, [trackProductViewed]);

  const pages = useMemo(() => {
    if (type === 'basic-info') {
      return [
        <ExplainerScreen
          key={0}
          type={type}
          nextStep={() => {
            setStep(step + 1);
          }}
        />,
        <YourStory key={1} />,
        <YourCareer key={2} />,
        <YourSocials key={3} />,
      ];
    }
    if (!isEdit && isFirstTime && type !== 'basic-info') {
      return [
        <ExplainerScreen
          key={0}
          type={type}
          nextStep={() => {
            setStep(step + 1);
          }}
        />,
        ...(artist ? [<YourStory key={1} />] : [<ConnectSpotifyCTACard key={1} />]),
        <YourCareer key={2} />,
        <YourSocials key={3} />,
        <ReleaseSelection key={4} canPage={canPage} isPressRelease={type === 'press-release'} />,
        <AIGenerationAndRefinement
          key={5}
          type={type}
          outputGeneration={(output) => {
            setContentGeneration(output);
          }}
          isGenerating={(output) => {
            setIsGenerating(output);
          }}
        />,
      ];
    }
    if (!isEdit && !isFirstTime && type !== 'basic-info') {
      return [
        <ExplainerScreen
          key={0}
          type={type}
          nextStep={() => {
            setStep(step + 1);
          }}
        />,
        <ReleaseSelection key={1} canPage={canPage} isPressRelease={type === 'press-release'} />,
        <YourCareer key={2} />,
        <AIGenerationAndRefinement
          key={3}
          type={type}
          outputGeneration={(output) => {
            setContentGeneration(output);
          }}
          isGenerating={(output) => {
            setIsGenerating(output);
          }}
        />,
      ];
    }
    if (isEdit && type !== 'basic-info') {
      return [
        <AIGenerationAndRefinement
          key={0}
          type={type}
          isEdit={isEdit}
          outputGeneration={(output) => {
            setContentGeneration(output);
          }}
          isGenerating={(output) => {
            setIsGenerating(output);
          }}
        />,
      ];
    }
    return [];
  }, [artist, isEdit, isFirstTime, step, type]);

  return (
    <div className="promote-modal-container text-center">
      {!isEdit && step !== 0 && artist && <NumberStepper steps={pages.length - 1} stepNumber={step} />}
      <DialogModal
        open={dialogOpen}
        title={'DIALOGS.QUIT-AI-GENERATOR'}
        content={'DIALOGS.ARE-YOU-SURE-YOU-WANT-TO-QUIT'}
        output={(output) => {
          handleDialogOutput(output);
        }}
      />
      <Button
        className="icon-btn close-button"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <Icon>close</Icon>
      </Button>
      <FormProvider {...formMethods}>
        <MotionWrapper isNext={direction === 'next'} key={step}>
          <MotionElement isNext={direction === 'next'}>{pages[step]}</MotionElement>
        </MotionWrapper>
      </FormProvider>
      {/* {type === 'basic-info' && (
        <FormProvider {...formMethods}>
          {step === 0 && (
            <ExplainerScreen
              type={type}
              nextStep={() => {
                setStep(step + 1);
              }}
            />
          )}
          {!artist && step === 1 && <ConnectSpotifyCTACard />}
          {artist && step === 1 && <YourStory />}
          {step === 2 && <YourCareer />}
          {step === 3 && <YourSocials />}
        </FormProvider>
      )}
      {!isEdit && isFirstTime && type !== 'basic-info' && (
        <FormProvider {...formMethods}>
          {step === 0 && (
            <ExplainerScreen
              type={type}
              nextStep={() => {
                setStep(step + 1);
              }}
            />
          )}
          {!artist && step === 1 && <ConnectSpotifyCTACard />}
          {artist && step === 1 && <YourStory />}
          {step === 2 && <YourCareer />}
          {step === 3 && <YourSocials />}
          {step === 4 && <ReleaseSelection canPage={canPage} isPressRelease={type === 'press-release'} />}
          {step === 5 && (
            <AIGenerationAndRefinement
              type={type}
              outputGeneration={(output) => {
                setContentGeneration(output);
              }}
              isGenerating={(output) => {
                setIsGenerating(output);
              }}
            />
          )}
        </FormProvider>
      )}
      {!isEdit && !isFirstTime && type !== 'basic-info' && (
        <FormProvider {...formMethods}>
          {step === 0 && (
            <ExplainerScreen
              type={type}
              nextStep={() => {
                setStep(step + 1);
              }}
            />
          )}
          {step === 1 && <ReleaseSelection canPage={canPage} isPressRelease={type === 'press-release'} />}
          {step === 2 && <YourCareer />}
          {step === 3 && (
            <AIGenerationAndRefinement
              type={type}
              outputGeneration={(output) => {
                setContentGeneration(output);
              }}
              isGenerating={(output) => {
                setIsGenerating(output);
              }}
            />
          )}
        </FormProvider>
      )}
      {isEdit && type !== 'basic-info' && (
        <AIGenerationAndRefinement
          type={type}
          isEdit={isEdit}
          outputGeneration={(output) => {
            setContentGeneration(output);
          }}
          isGenerating={(output) => {
            setIsGenerating(output);
          }}
        />
      )} */}

      {(step !== 0 || isEdit) && artist && (
        <div className="promote-footer">
          <div className={`card-inner w90p p10 pl20 m-auto d-flex`}>
            <div className="ml-auto">
              {step > 1 && (
                <Button
                  className="border-btn"
                  disabled={type !== 'basic-info' && (isSavingLoading || isGenerating)}
                  onClick={() => {
                    setDirection('back');
                    setStep(step > 1 ? step - 1 : step);
                  }}
                >
                  <Icon className="ml-8">chevron_left</Icon>
                  {t('COMMON.BACK')}
                </Button>
              )}
              {step < pages.length - 1 && !isEdit && (
                <Button
                  className="btn-white"
                  disabled={!canStep || isSavingLoading}
                  onClick={async () => {
                    if (step === (type === 'basic-info' ? pages.length - 1 : pages.length - 2)) {
                      await updateUserInfo();
                    }
                    setDirection('next');
                    setStep(step < pages.length - 1 ? step + 1 : step);
                  }}
                >
                  {isSavingLoading ? <CircularProgress size={16} /> : t('COMMON.CONTINUE')}
                  <Icon className="mr-8">chevron_right</Icon>
                </Button>
              )}
              {(isFirstTime && type !== 'basic-info'
                ? step === pages.length - 1
                : step === pages.length - 1 || isEdit) && (
                <Button
                  className="btn-white"
                  disabled={!isValid || isSavingLoading || isGenerating}
                  onClick={() => {
                    if (type === 'basic-info') {
                      return updateUserInfo();
                    }
                    saveGeneration();
                  }}
                >
                  {isSavingLoading ? <CircularProgress size={16} /> : t('COMMON.SAVE-AND-FINISH')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIGeneratorModal;
