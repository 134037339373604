import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { format } from 'date-fns';

import Loading from '@/components/utility/Loading';
import Card from '@/components/utility/microcomponents/Card';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useMarketingAssistantContent from '@/hooks/marketing-assistant/useMarketingAssistantContent';
import MarketingAssistantAPI from '@/network/MarketingAssistantAPI';

const AIGenerationAndRefinement = ({
  type,
  outputGeneration,
  isEdit,
  isGenerating,
}: {
  type: string;
  outputGeneration: (generation: string) => void;
  isEdit?: boolean;
  isGenerating: (output: boolean) => void;
}) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const { dispatchSnackbar } = useSnackbarContext();

  const hasRunGeneration = useRef(false);

  const [artistBio, setArtistBio] = useState<string>('');
  const [pressRelease, setPressRelease] = useState<string>('');
  const [isGenerationLoading, setIsGenerationLoading] = useState<boolean>(false);

  const { marketingAssistantContent } = useMarketingAssistantContent();

  const [editPrompt, setEditPrompt] = useState<string>('');

  const formValues = useFormContext();

  const generateBio = useCallback(() => {
    if (!accountId) return;

    const data = {
      accountId: accountId,
      releaseCycleId:
        formValues?.getValues('releaseCycleId') !== 'new' && formValues?.getValues('releaseCycleId') !== 'none'
          ? formValues.getValues('releaseCycleId')
          : null,
      ...(formValues?.getValues('releaseType') !== 'none' && {
        releaseTitle: formValues?.getValues('releaseTitle'),
        releaseGenres: formValues?.getValues('releaseGenres'),
        releaseCollaborators: formValues?.getValues('releaseCollaborators'),
        releaseDate: formValues?.getValues('releaseDate')
          ? format(new Date(formValues?.getValues('releaseDate')), 'yyyy-MM-dd')
          : '',
        releaseDescription: formValues?.getValues('releaseDescription'),
      }),
    };

    setIsGenerationLoading(true);
    MarketingAssistantAPI.generateArtistBio({
      data: data,
    })
      .then((resp) => {
        setArtistBio(resp.data.content);
        outputGeneration(resp.data.content);
        setIsGenerationLoading(false);
      })
      .catch((error) => {
        setIsGenerationLoading(false);
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: error.response.data.errorMessage,
            type: 'error',
          },
        });
      });
  }, [accountId, dispatchSnackbar, formValues, outputGeneration]);

  const generatePressRelease = useCallback(() => {
    if (!accountId) return;
    const data = {
      accountId: accountId,
      releaseCycleId:
        formValues?.getValues('releaseCycleId') !== 'new' && formValues?.getValues('releaseCycleId') !== 'none'
          ? formValues.getValues('releaseCycleId')
          : null,
      ...(formValues?.getValues('releaseType') !== 'none' && {
        releaseTitle: formValues?.getValues('releaseTitle'),
        releaseGenres: formValues?.getValues('releaseGenres'),
        releaseCollaborators: formValues?.getValues('releaseCollaborators') || [],
        releaseDate: formValues?.getValues('releaseDate')
          ? format(new Date(formValues?.getValues('releaseDate')), 'yyyy-MM-dd')
          : '',
        releaseDescription: formValues?.getValues('releaseDescription'),
        releaseType: 0,
      }),
    };
    setIsGenerationLoading(true);
    MarketingAssistantAPI.generatePressRelease({
      data: data,
    })
      .then((resp) => {
        setPressRelease(resp.data.content);
        outputGeneration(resp.data.content);
        setIsGenerationLoading(false);
      })
      .catch((error) => {
        setIsGenerationLoading(false);
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: error.response.data.errorMessage,
            type: 'error',
          },
        });
      });
  }, [accountId, dispatchSnackbar, formValues, outputGeneration]);

  const editGeneration = useCallback(() => {
    if (!accountId) return;
    setIsGenerationLoading(true);
    MarketingAssistantAPI.editGeneration({
      data: {
        type: type === 'artist-bio' ? 0 : 1,
        content: type === 'artist-bio' ? artistBio : pressRelease,
        editRequest: editPrompt,
        accountId: accountId,
      },
    })
      .then((resp) => {
        if (type === 'artist-bio') {
          setArtistBio(resp.data.content);
          outputGeneration(resp.data.content);
        } else {
          setPressRelease(resp.data.content);
          outputGeneration(resp.data.content);
        }
        setEditPrompt('');
        setIsGenerationLoading(false);
      })
      .catch(() => {
        setEditPrompt('');
        setIsGenerationLoading(false);
      });
  }, [accountId, artistBio, editPrompt, outputGeneration, pressRelease, type]);

  const generate = useCallback(() => {
    if (editPrompt) {
      editGeneration();
      return;
    }
    if (type === 'artist-bio') {
      generateBio();
    } else {
      generatePressRelease();
    }
  }, [editGeneration, editPrompt, generateBio, generatePressRelease, type]);

  useEffect(() => {
    if (hasRunGeneration.current || isEdit) return;

    hasRunGeneration.current = true;

    generate();
  }, [generate, isEdit]);

  useEffect(() => {
    if (marketingAssistantContent) {
      const firstItem = marketingAssistantContent.items.filter((item) =>
        type === 'artist-bio' ? item.type === 0 : item.type === 1
      )[0]?.content;
      if (type === 'artist-bio') setArtistBio(firstItem);
      if (type === 'press-release') setPressRelease(firstItem);
    }
  }, [marketingAssistantContent, type]);

  useEffect(() => {
    if (artistBio || pressRelease) {
      outputGeneration(type === 'artist-bio' ? artistBio : pressRelease);
    }
  }, [artistBio, outputGeneration, pressRelease, type]);

  useEffect(() => {
    isGenerating(isGenerationLoading);
  }, [isGenerationLoading, isGenerating]);

  return (
    <div className={`text-left max-w600 ml-auto mr-auto ${isEdit ? '' : 'mt40'} mb230`}>
      {isGenerationLoading && (
        <div className="centered-loading">
          <Loading isGeneratorLoading />
        </div>
      )}
      {!isGenerationLoading && (
        <>
          {type === 'artist-bio' && <h4 className="text-center">Your artist bio</h4>}
          {type === 'press-release' && <h4 className="text-center">Your press release</h4>}
          <Card inner className="p16 mt10 border-grey-1">
            {type === 'artist-bio' && (
              <p className="">{artistBio || 'It looks like something went wrong - go back and try again'}</p>
            )}
            {type === 'press-release' && (
              <p className="">{pressRelease || 'It looks like something went wrong - go back and try again'}</p>
            )}
            {(artistBio || pressRelease) && (
              <div className="text-center mt20">
                <Button
                  className="btn-black min-w150"
                  onClick={async () => {
                    await navigator.clipboard.writeText(type === 'artist-bio' ? artistBio : pressRelease);
                    await dispatchSnackbar({
                      type: 'OPEN_SNACKBAR',
                      payload: {
                        message: t('COMMON.COPIED-TO-CLIPBOARD'),
                        type: 'success',
                      },
                    });
                  }}
                >
                  <CustomIcons className="mt4 pr8" name="copy" />
                  Copy
                </Button>
              </div>
            )}
          </Card>

          {(artistBio || pressRelease || isEdit) && (
            <>
              <Card inner className="p10 mt20">
                <h4 className="text-center">Update your generation</h4>
                <p className="text-faded text-center mt10">Tell our AI how you’d like to edit your bio</p>
                <textarea
                  className="mt20"
                  placeholder="E.g. make it friendlier"
                  value={editPrompt}
                  onChange={(e) => setEditPrompt(e.target.value)}
                />
                <div className="mt10 text-center">
                  <Button className="btn-white m0 min-w150" onClick={generate}>
                    <CustomIcons className="pr10" name="refresh" />
                    Regenerate
                  </Button>
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AIGenerationAndRefinement;
